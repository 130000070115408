import dataLayer from './dataLayer'

export interface Axeptio {
  on: (eventName: string, callback: () => void) => void
}

export default {
  init() {
    if (window.hasConsentMode && window._axcb) {
      window._axcb.push((axeptio: Axeptio): void => {
        axeptio.on('cookies:complete', function () {
          dataLayer.onResponseToConsent()
        })
      })
    } else {
      dataLayer.onResponseToConsent()
    }

    // On BS, Axeptio can be loaded without consent mode being configured on
    // the DCA.
    document.querySelectorAll('.consent-mode__open').forEach(function (el) {
      el.addEventListener('click', function (e) {
        e.preventDefault()
        if (window.openAxeptioCookies) {
          window.openAxeptioCookies()
        }
      })
    })
  },
}

'use strict'

import dataLayer from 'assets/core/js/module/dataLayer'

type products = Record<number, Record<string, object>>

export default function (): void {
  dataLayer.pushOnElementVisible(
    window.coreGtmId,
    document.querySelectorAll(
      '[data-pagetype~="search"] .search-results-list .search-result > [data-id], [data-pagetype~="search"] .dca-results__list .dca-result[data-id], [data-pagetype~="search"] .results__list > [data-id]'
    ),
    (data, els) => {
      const products: object[] = []
      els.forEach((el) => {
        const dataId = el.getAttribute('data-id')
        if (dataId === null) {
          return false
        }
        const productId = parseInt(dataId)
        if (!data.products) {
          return false
        }
        const product = (data.products as products)[productId]
        if (product) {
          const productKey = Object.keys(product)[0]
          if (productKey !== undefined) {
            const productData = product[productKey]
            if (productData !== undefined) {
              products.push(productData) // data are nested, we extract just data, ignore key
            }
          }
        }
      })
      if (!products.length) {
        return false
      }

      return {
        event: dataLayer.events.SEARCH_VIEW_PRODUCTS,
        item_list_name: data.searchType,
        products,
      }
    }
  )

  dataLayer.pushOnCustomEvent(window.coreGtmId, window, 'productPageLink.opened', function (data, el, domEvent) {
    if (!(domEvent instanceof CustomEvent) || domEvent.detail === null || typeof domEvent.detail !== 'object') {
      return false
    }

    const productId = (domEvent.detail as { productId: number }).productId
    const products = data.products as products
    if (!products || !products[productId]) {
      return false
    }

    const product = products[productId]
    if (!product) {
      return false
    }

    const eventData = {
      event: dataLayer.events.SEARCH_SELECT_PRODUCT,
      item_list_name: data.searchType,
    }
    Object.assign(eventData, product)

    return eventData
  })

  dataLayer.pushInLayer(window.coreGtmId, {
    event: dataLayer.events.OLD_SEARCH,
    // @NOTE : before, we can send 'list' or 'map' but we can't open the page with map visible by default so it is always 'list display'
    displaying: 'list',
    sorting: dataLayer.getData(window.coreGtmId).searchSortBy,
  })

  dataLayer.pushOnCustomEvent(window.coreGtmId, window, 'search.filters.change', (data, el, domEvent) => {
    if (!(domEvent instanceof CustomEvent) || domEvent.detail === null || typeof domEvent.detail !== 'object') {
      return false
    }

    const infos = domEvent.detail as { filterCategory: string; label: string }
    return {
      event: dataLayer.events.OLD_SEARCH,
      filterCategory: infos.filterCategory,
      filterLabel: infos.label,
    }
  })
}
